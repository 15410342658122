.header {
  background: linear-gradient(to bottom, #466086, #cae5fa 100%);
  padding: 40px;
  color: #141443;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 60px;
}

.name {
  opacity: 0;
  transition: opacity 2s ease-in-out;
  font-size: 60px;
}

.name.fade-in {
  opacity: 1;
}

.slogan {
  opacity: 0;
  transition: opacity 5s ease-in-out;
  font-style: italic;
  font-family: 'Raleway', bold, serif;
}

.slogan.fade-in {
  opacity: 1;
}

.logo {
  padding-top: 10px;
  margin-bottom: -30px;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.logo.fade-in {
  opacity: 1;
}

.logo img {
  width: 100px;
}
